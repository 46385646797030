import React from "react"

import Logo from "@static/images/logo.svg"
import LogoTv from "@static/images/logo-tv.svg"
import IconAccount from "@static/images/account.svg"
import IconArrowRight from "@static/images/arrow-right.svg"
import IconCarouselChevronLeft from "@static/images/carousel-chevron-left.svg"
import IconCarouselChevronRight from "@static/images/carousel-chevron-right.svg"
import IconCall from "@static/images/call.svg"
import IconCart from "@static/images/cart.svg"
import IconChainLink from "@static/images/chain-link.svg"
import IconChevronLeft from "@static/images/chevron-left.svg"
import IconChevronRight from "@static/images/chevron-right.svg"
import IconChevronDownDropdown from "@static/images/chevron-down-dropdown.svg"
import IconClose from "@static/images/close.svg"
import IconCloseLarge from "@static/images/close-large.svg"
import IconCrosshair from "@static/images/crosshair.svg"
import CloseAlt from "@static/images/close-alt.svg"
import IconDots from "@static/images/dots.svg"
import IconEdit from "@static/images/edit.svg"
import IconEyes from "@static/images/eyes.svg"
import IconEyesCurrentColor from "@static/images/eyesCurrentColor.svg"
import IconFacebook from "@static/images/facebook.svg"
import IconGift from "@static/images/gift.svg"
import IconHeart from "@static/images/heart.svg"
import IconHeartFaceFilled from "@static/images/heart-face-filled.svg"
import IconHeartFaceUnfilled from "@static/images/heart-face-unfilled.svg"
import IconInstagram from "@static/images/instagram.svg"
import IconLightBulb from "@static/images/light-bulb.svg"
import IconList from "@static/images/list.svg"
import IconLocation from "@static/images/location.svg"
import IconMail from "@static/images/mail.svg"
import IconMessenger from "@static/images/messenger.svg"
import IconMinus from "@static/images/minus.svg"
import IconPhone from "@static/images/phone.svg"
import IconPin from "@static/images/pin.svg"
import IconPlus from "@static/images/plus.svg"
import IconPlusFaq from "@static/images/plus-faq.svg"
import IconPlusProduct from "@static/images/plus-product.svg"
import IconPressPlay from "@static/images/press-play.svg"
import IconPressPlayArrow from "@static/images/press-play-arrow.svg"
import IconRecording from "@static/images/recording.svg"
import IconSaleBadge from "@static/images/sale-badge.svg"
import IconSearch from "@static/images/search.svg"
import IconSmilingMind from "@static/images/smiling-mind.svg"
import IconSort from "@static/images/sort.svg"
import IconSpaceInvader from "@static/images/space-invader.svg"
import IconSweet from "@static/images/sweet.svg"
import IconTime from "@static/images/time.svg"
import IconTiktok from "@static/images/tiktok.svg"
import IconWavy from "@static/images/wavy.svg"
import IconWavyLong from "@static/images/wavy-long.svg"
import IconWavyExtraLong from "@static/images/wavy-extra-long.svg"
import IconYoutube from "@static/images/youtube.svg"
import IconCloseSmall from "@static/images/close-small.svg"
import MenuIcon from "@static/images/menuIcon.svg"
import Tick from "@static/images/tick.svg"
import Grid from "@static/images/grid.svg"
import RoundedBox from "@static/images/roundedBox.svg"
import ChevronRounded from "@static/images/chevronRounded.svg"
import CarouselChevron from "@static/images/carouselChevron.svg"
import IconSkinQuizDry from "@static/images/skin-quiz-dry.svg"
import IconSkinQuizOily from "@static/images/skin-quiz-oily.svg"
import IconSkinQuizCombination from "@static/images/skin-quiz-combination.svg"
import IconSkinQuizBlemish from "@static/images/skin-quiz-blemish.svg"
import IconSkinQuizNothing from "@static/images/skin-quiz-nothing.svg"
import IconSkinQuizSensitive from "@static/images/skin-quiz-sensitive.svg"
import IconStorePin from "@static/images/pin.svg"
import IconSemiCircles from "@static/images/semi-circles.svg"
import IconLimitedEdition from "@static/images/limited-edition.svg"
import IconChocolateDrip from "@static/images/chocolate-drip.svg"
import IconSnoozeville from "@static/images/snoozeville.svg"
import IconCosmicChoc from "@static/images/cosmic-choc.svg"
import IconChupaChups from "@static/images/chupa-chups.svg"
import IconChupaChupsLayer from "@static/images/chupa-chups-layer.svg"
import Hamburger from "@static/images/hamburger.svg"
import IconChevronRightMenu from "@static/images/chevron-right-menu.svg"
import IconTrash from "@static/images/trash.svg"
import IconEmail from "@static/images/email.svg"
import IconCopy from "@static/images/copy.svg"
import ReviewStarFull from "@static/images/review-star-full.svg"
import ReviewStarEmpty from "@static/images/review-star-empty.svg"
import ReviewStarHalf from "@static/images/review-star-half.svg"
import Tooltip from "@static/images/tooltip.svg"

import { Box, BoxProps } from "@chakra-ui/react"

type Props = {
  name: string
  title?: string
  width?: any
  height?: any
  props?: any
} & BoxProps

type Icon = {
  id: string
  url: string
  viewBox: string
}

type Icons = {
  [key: string]: Icon
}

const Icon: React.FC<Props> = ({ name, title, width = "auto", height = "auto", ...props }) => {
  const icons: Icons = {
    logo: Logo,
    logoTv: LogoTv,
    account: IconAccount,
    arrowRight: IconArrowRight,
    carouselChevronLeft: IconCarouselChevronLeft,
    carouselChevronRight: IconCarouselChevronRight,
    call: IconCall,
    cart: IconCart,
    chainLink: IconChainLink,
    chevronLeft: IconChevronLeft,
    chevronRight: IconChevronRight,
    chevronRightMenu: IconChevronRightMenu,
    chevronDownDropdown: IconChevronDownDropdown,
    closeSmall: IconCloseSmall,
    close: IconClose,
    closeAlt: CloseAlt,
    closeLarge: IconCloseLarge,
    crosshair: IconCrosshair,
    dots: IconDots,
    edit: IconEdit,
    eyes: IconEyes,
    eyesCurrentColor: IconEyesCurrentColor,
    facebook: IconFacebook,
    gift: IconGift,
    hamburger: Hamburger,
    heart: IconHeart,
    heartFaceFilled: IconHeartFaceFilled,
    heartFaceUnfilled: IconHeartFaceUnfilled,
    instagram: IconInstagram,
    lightBulb: IconLightBulb,
    list: IconList,
    location: IconLocation,
    mail: IconMail,
    messenger: IconMessenger,
    minus: IconMinus,
    phone: IconPhone,
    pin: IconPin,
    plus: IconPlus,
    plusFaq: IconPlusFaq,
    plusProduct: IconPlusProduct,
    pressPlay: IconPressPlay,
    pressPlayArrow: IconPressPlayArrow,
    recording: IconRecording,
    saleBadge: IconSaleBadge,
    search: IconSearch,
    smilingMind: IconSmilingMind,
    sort: IconSort,
    spaceInvader: IconSpaceInvader,
    sweet: IconSweet,
    time: IconTime,
    tiktok: IconTiktok,
    wavy: IconWavy,
    wavyLong: IconWavyLong,
    wavyExtraLong: IconWavyExtraLong,
    youtube: IconYoutube,
    menuIcon: MenuIcon,
    tick: Tick,
    grid: Grid,
    roundedBox: RoundedBox,
    chevronRounded: ChevronRounded,
    carouselChevron: CarouselChevron,
    skinQuizDry: IconSkinQuizDry,
    skinQuizOily: IconSkinQuizOily,
    skinQuizCombination: IconSkinQuizCombination,
    skinQuizBlemish: IconSkinQuizBlemish,
    skinQuizNothing: IconSkinQuizNothing,
    skinQuizSensitive: IconSkinQuizSensitive,
    storePin: IconStorePin,
    semiCircles: IconSemiCircles,
    limitedEdition: IconLimitedEdition,
    chocolateDrip: IconChocolateDrip,
    snoozeville: IconSnoozeville,
    cosmicChoc: IconCosmicChoc,
    chupaChups: IconChupaChups,
    chupaChupsLayer: IconChupaChupsLayer,
    trash: IconTrash,
    email: IconEmail,
    copy: IconCopy,
    reviewStarFull: ReviewStarFull,
    reviewStarEmpty: ReviewStarEmpty,
    reviewStarHalf: ReviewStarHalf,
    tooltip: Tooltip,
  }

  const icon = icons[name]

  return icon ? (
    <Box width={width} height={height} {...props}>
      <svg viewBox={icon.viewBox} height="100%" width="100%" id={icon.id}>
        {title && <title>{title}</title>}
        <use xlinkHref={icon.url} />
      </svg>
    </Box>
  ) : (
    <p>[{title || name}]</p>
  )
}

export default React.memo(Icon)
