module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@8.49.0__encoding@0.1.1_iv3fv6xjf7oyq55ndsgp7z3cyi/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Allkinds","short_name":"Allkinds","description":"Say hello to awesome: all kinds of bath, body, haircare, skincare & fun stuff made just for kids. And with zero gross stuff.","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"static/images/icon.png","icons":[{"src":"static/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79f711f440d9ca6a12a51ef8bb2ad377"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-loadable-components-ssr@4.3.2_@babel+core@7.22.9_@loadable+component@5.15.3_rea_btnuyhdjngwq7xqevs54jzsnyq/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@8.49.0__encoding@0._f5sqpeiitflm55sdmos2i3fjeu/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_eslint@8.49.0__encoding@0.1.13_react-dom@18.2.0_react@18.2._dqahbdbx3uq43qpgkoh7budvmi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
